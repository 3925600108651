import moment from "moment";

const splitTimeRange = (
  startTime: string,
  endTime: string,
  interval: number
): string[] => {
  const start = moment(startTime, "HH:mm");
  const end = moment(endTime, "HH:mm");
  const timeSlots: string[] = [];

  while (start <= end) {
    timeSlots.push(start.format("HH:mm"));
    start.add(interval, "minutes");
  }

  return timeSlots;
};

export const getTimeSlotsForDate = (
  date: string,
  selectedParty: number,
  shiftData: any
): any[] => {
  const timeSlots: any[] = [];
  const dayName = moment(date).format("dddd").toUpperCase();
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const formatted = moment(date).format("M/D/YYYY");
  const onetimeSlots: any[] = [];
  const recurringSlots: any[] = [];
  shiftData.forEach((shift: any) => {
    if (shift.Type === "ONETIME") {
      const onetime = [shift.TimeSchedule];
      const isActive = generateUniqueDates(onetime).some((shiftDay: any) => {
        return shiftDay === formatted;
      });
      if (
        isActive &&
        selectedParty >= shift.ShiftAvailability.ReservationCover.Minimum &&
        selectedParty <= shift.ShiftAvailability.ReservationCover.Maximum
      ) {
        const slots = splitTimeRange(
          shift.ActiveTime.First,
          shift.ActiveTime.Last,
          shift.ShiftAvailability.Timeslot
        );
        if (timeSlots[timeSlots.length - 1] === slots[0]) {
          slots.shift();
        }
        onetimeSlots.push(...slots);
      }
    }
    if (shift.IsActive && shift.Type === "RECURRING") {
      const shiftActiveDay = shift.ShiftActiveDay.find((shiftDay: any) => {
        return shiftDay.Day === dayName || shiftDay.Day === formattedDate;
      });
      if (
        shiftActiveDay &&
        selectedParty >= shift.ShiftAvailability.ReservationCover.Minimum &&
        selectedParty <= shift.ShiftAvailability.ReservationCover.Maximum
      ) {
        const slots = splitTimeRange(
          shiftActiveDay.StartTime,
          shiftActiveDay.EndTime,
          shift.ShiftAvailability.Timeslot
        );
        if (timeSlots[timeSlots.length - 1] === slots[0]) {
          slots.shift();
        }
        recurringSlots.push(...slots);
      }
    }
  });
  if (onetimeSlots.length > 0) {
    const removeAfterTime = (
      firstArray: string[],
      secondArray: string[]
    ): string[] => {
      const firstTime = firstArray[0];
      const filteredArray = secondArray.filter((time) => time <= firstTime);
      const combinedArray = [...filteredArray, ...firstArray].sort((a, b) => {
        const [aHour, aMinute] = a.split(":").map(Number);
        const [bHour, bMinute] = b.split(":").map(Number);
        return aHour === bHour ? aMinute - bMinute : aHour - bHour;
      });

      return combinedArray;
    };

    const result = removeAfterTime(onetimeSlots, recurringSlots);
    timeSlots.push(...result);
  } else {
    timeSlots.push(...recurringSlots);
  }
  return timeSlots;
};
export const getAllTimeSlotsForDate = (date: string, shiftData: any): any[] => {
  const timeSlots: any[] = [];
  const dayName = moment(date).format("dddd").toUpperCase();
  const formattedDate = moment(date).format("YYYY-MM-DD");
  const formatted = moment(date).format("M/D/YYYY");

  const onetimeSlots: any[] = [];
  const recurringSlots: any[] = [];
  shiftData.forEach((shift: any) => {
    if (shift.Type === "ONETIME") {
      const onetime = [shift.TimeSchedule];
      const isActive = generateUniqueDates(onetime).some((shiftDay: any) => {
        return shiftDay === formatted;
      });
      if (isActive) {
        const slots = splitTimeRange(
          shift.ActiveTime.First,
          shift.ActiveTime.Last,
          shift.ShiftAvailability.Timeslot
        );
        if (timeSlots[timeSlots.length - 1] === slots[0]) {
          slots.shift();
        }
        onetimeSlots.push(...slots);
      }
    }
    if (shift.IsActive && shift.Type === "RECURRING") {
      const shiftActiveDay = shift.ShiftActiveDay.find((shiftDay: any) => {
        return shiftDay.Day === dayName || shiftDay.Day === formattedDate;
      });
      if (shiftActiveDay) {
        const slots = splitTimeRange(
          shiftActiveDay.StartTime,
          shiftActiveDay.EndTime,
          shift.ShiftAvailability.Timeslot
        );
        if (timeSlots[timeSlots.length - 1] === slots[0]) {
          slots.shift();
        }
        recurringSlots.push(...slots);
      }
    }
  });
  if (onetimeSlots.length > 0) {
    const removeAfterTime = (
      firstArray: string[],
      secondArray: string[]
    ): string[] => {
      const firstTime = firstArray[0];
      const filteredArray = secondArray.filter((time) => time <= firstTime);
      const combinedArray = [...filteredArray, ...firstArray].sort((a, b) => {
        const [aHour, aMinute] = a.split(":").map(Number);
        const [bHour, bMinute] = b.split(":").map(Number);
        return aHour === bHour ? aMinute - bMinute : aHour - bHour;
      });

      return combinedArray;
    };

    const result = removeAfterTime(onetimeSlots, recurringSlots);
    timeSlots.push(...result);
  } else {
    timeSlots.push(...recurringSlots);
  }
  return timeSlots;
};
export const getReservationCovers = (
  selectedDate: string,
  shiftData: any
): number[] => {
  const filteredShifts = shiftData.filter((shift: any) => {
    const formattedDate = moment(selectedDate).format("YYYY-MM-DD");
    const dayName = moment(selectedDate).format("dddd").toUpperCase();
    const formatted = moment(selectedDate).format("M/D/YYYY");
    if (shift.Type === "RECURRING") {
      return shift.ShiftActiveDay.some((shiftDay: any) => {
        return shiftDay.Day === dayName || shiftDay.Day === formattedDate;
      });
    }
    if (shift.Type === "ONETIME") {
      const onetime = [shift.TimeSchedule];
      return generateUniqueDates(onetime).some((shiftDay: any) => {
        return shiftDay === formatted;
      });
    }
  });
  if (filteredShifts.length === 0) {
    return [];
  }

  const allMinimums = filteredShifts.map(
    (shift: any) => shift.ShiftAvailability.ReservationCover.Minimum
  );
  const allMaximums = filteredShifts.map(
    (shift: any) => shift.ShiftAvailability.ReservationCover.Maximum
  );

  const min = Math.min(...allMinimums);
  const max = Math.max(...allMaximums);

  const numberArray: number[] = [];
  for (let i = min; i <= max; i++) {
    numberArray.push(i);
  }

  return numberArray;
};
export const convertTime = (isoString: string) => {
  const date = new Date(isoString);
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};
export const getLocations = (data: any) => {
  let resultArray = data.reduce((acc: any, location: any) => {
    const orgName = location.OrganizationName;
    if (!acc[orgName]) {
      acc[orgName] = [];
    }
    acc[orgName].push(location);
    return acc;
  }, {});
  return resultArray;
};
export const convertDate = (isoString: string) => {
  const date = new Date(isoString);
  return date;
};
export const generateUniqueDates = (dateRanges: any[]) => {
  const dateSet = new Set();
  const result: any[] = [];

  dateRanges.forEach(({ StartDate, EndDate }) => {
    let currentDate = new Date(StartDate);
    const endDate = new Date(EndDate);
    while (currentDate <= endDate) {
      const dateString = currentDate.toLocaleDateString();
      if (!dateSet.has(dateString)) {
        dateSet.add(dateString);
        result.push(dateString);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  });

  return result;
};
