import React, { useState, useEffect } from "react";
import left from "../../../assets/slide-left.svg";
import add from "../../../assets/add.svg";
import link3 from "../../../assets/list-gray.svg";
import grade from "../../../assets/list.svg";
import grouping from "../../../assets/av-gray.svg";
import groupingPr from "../../../assets/av.svg";
import customer from "../../../assets/customer.svg";
import cust from "../../../assets/cust.svg";
import { useNavigate } from "react-router-dom";
import home from "../../../assets/fav.png";
import "./SideBarStyles.scss";

interface SideBarProps {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selected: string;
}

const SideBar: React.FC<SideBarProps> = ({
  isDrawerOpen,
  setIsDrawerOpen,
  selected,
}) => {
  const navigate = useNavigate();

  const [activeIcon, setActiveIcon] = useState<string | null>(null);

  const ICONS = [
    {
      img: link3,
      header: "View",
      type: "VIEW",
      subTypes: ["FLOOR", "LIST"],
      selectedImg: grade,
    },
    // {
    //   img: grouping,
    //   header: "Availability",
    //   type: "AVAILABILITY",
    //   subTypes: ["TABLE GROUPING", "SHIFT MANAGEMENT"],
    //   selectedImg: groupingPr,
    // },
    {
      img: cust,
      header: "Customer Management",
      type: "CUSTOMER",
      subTypes: [],
      selectedImg: customer,
    },
  ];

  const getMenu = () => {
    let menu = ICONS.find((icon: any) => icon.subTypes.includes(selected));
    return menu?.type;
  };

  const handleOutsideClick = (e: MouseEvent) => {
    const targetElement = e.target as HTMLElement;
    if (
      !targetElement.closest(".iconList") &&
      !targetElement.closest(".subTypePopup")
    ) {
      setActiveIcon(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOnClick = (subType: string) => {
    switch (subType) {
      case "FLOOR":
        navigate("/v1/booking/floor-view");
        break;
      case "LIST":
        navigate("/v1/booking/list-view");
        break;
      case "TABLE GROUPING":
        navigate("/v1/booking/grouping");
        break;
      case "SHIFT MANAGEMENT":
        navigate("/v1/booking/shift-management");
        break;
      default:
        navigate("/v1/booking/list-view");
        break;
    }
    setActiveIcon(null); // Close the popup after navigation
  };

  return (
    <div className='SideBar'>
      <div className='homeIC'>
        <img
          alt=''
          src={home}
          onClick={() => {
            navigate("/v1/booking/list-view");
          }}
        />
        {window.screen.width > 1280 ? (
          <div
            onClick={() => {
              localStorage.setItem("drawerOpen", JSON.stringify(!isDrawerOpen));
              setIsDrawerOpen(!isDrawerOpen);
            }}
            className='slide'
          >
            <img alt='' src={left} className='left' />
          </div>
        ) : (
          ""
        )}
      </div>
      <div
        className='addBtn'
        onClick={() => {
          navigate("/v1/booking/new-reservation");
        }}
      >
        <img alt='' src={add} />
      </div>
      <hr />
      {ICONS.map((icon, index) => (
        <div
          className={
            icon.subTypes.length > 0
              ? icon.subTypes.includes(selected) && icon.type === getMenu()
                ? "iconListSelected"
                : "iconList"
              : icon.type === selected
              ? "iconListSelected"
              : "iconList"
          }
          key={index}
          onMouseEnter={() => setActiveIcon(icon.type)}
          onClick={() => {
            if (icon.type === "CUSTOMER") {
              navigate("/v1/booking/customer-management");
            } else {
              setActiveIcon((prev) => (prev === icon.type ? null : icon.type));
            }
          }}
        >
          <img
            alt=''
            src={
              icon.subTypes.length > 0
                ? icon.subTypes.includes(selected) && icon.type === getMenu()
                  ? icon.selectedImg
                  : icon.img
                : icon.type === selected
                ? icon.selectedImg
                : icon.img
            }
          />
          {activeIcon === icon.type && icon.subTypes.length > 0 && (
            <div
              className='subTypePopup'
              onMouseLeave={() => setActiveIcon(null)}
            >
              <div className='type'>
                <div>{icon.type.toLowerCase()}</div>
              </div>
              {icon.subTypes.map((subType, subIndex) => (
                <div
                  key={subIndex}
                  className='subTypeItem'
                  onClick={() => {
                    handleOnClick(subType);
                  }}
                >
                  {subType.toLowerCase()}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SideBar;
