import React, { useState, useEffect } from "react";
import "./ShiftTimeWindowStyles.scss";
import trash from "../../../../assets/trash.svg";

interface ShiftTimeWindowProps {
  data: {
    Id: string;
    StartTime: string;
    EndTime: string;
    Percentage: number;
    Configuration: {
      Busy: boolean;
      Unavailable: boolean;
    };
  };
  timeInterval: number;
  updateTimeWindow: (
    id: string,
    updatedFields: Partial<ShiftTimeWindowProps["data"]>
  ) => void;
  setTimeWindows: React.Dispatch<React.SetStateAction<any>>;
  timeWindows: any[];
  startTime: string;
  endTime: string;
  startTimeForEndTime: string;
  invalid: any[];
}

const ShiftTimeWindow: React.FC<ShiftTimeWindowProps> = ({
  data,
  timeInterval,
  updateTimeWindow,
  setTimeWindows,
  timeWindows,
  startTime,
  endTime,
  startTimeForEndTime,
  invalid,
}) => {
  const [selectedStatus, setSelectedStatus] = useState<string>(
    data?.Configuration?.Busy ? "BUSY" : "UNAVAILABLE"
  );
  const generateTimeSlots = (startTime: string, endTime: string): string[] => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const timeSlots: string[] = [];
    while (start <= end) {
      const formattedTime = start.toTimeString().slice(0, 5);
      timeSlots.push(formattedTime);
      start.setMinutes(start.getMinutes() + timeInterval);
    }
    return timeSlots;
  };
  const generateEndTimeSlots = (
    startTime: string,
    endTime: string
  ): string[] => {
    const start = new Date(`1970-01-01T${startTime}:00`);
    const end = new Date(`1970-01-01T${endTime}:00`);
    const timeSlots: string[] = [];
    start.setMinutes(start.getMinutes() + timeInterval);
    while (start <= end) {
      const formattedTime = start.toTimeString().slice(0, 5);
      timeSlots.push(formattedTime);
      start.setMinutes(start.getMinutes() + timeInterval);
    }
    return timeSlots;
  };
  const slots = generateTimeSlots(startTime, endTime);
  const endTimeSlots = generateEndTimeSlots(startTimeForEndTime, endTime);

  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const numericValue = value.replace(/\D/g, "");
    updateTimeWindow(data.Id, { Percentage: Number(numericValue) });
  };

  const handleStatusChange = (status: string) => {
    setSelectedStatus(status);
    if (status === "BUSY") {
      updateTimeWindow(data.Id, {
        Configuration: { Busy: true, Unavailable: false },
      });
    } else {
      updateTimeWindow(data.Id, {
        Configuration: { Busy: false, Unavailable: true },
      });
    }
  };
  const findClosestTime = (targetTime: string) => {
    if (endTimeSlots.includes(targetTime)) {
      return targetTime;
    }

    const nextClosest = endTimeSlots.find((time) => time > targetTime);

    return nextClosest || endTimeSlots[endTimeSlots.length - 1];
  };

  useEffect(() => {
    let value = findClosestTime(data.EndTime);
    updateTimeWindow(data.Id, { EndTime: value });
  }, [timeInterval]);
  return (
    <div className='ShiftTimeWindowContainer'>
      <div className='ShiftTimeWindow'>
        <div className='window-container'>
          <div className='window-top'>
            <div className='start'>
              <div className='header'>Start Time</div>
              <select
                id='startTime'
                value={data.StartTime}
                onChange={(e) =>
                  updateTimeWindow(data.Id, { StartTime: e.target.value })
                }
              >
                {slots.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className='start'>
              <div className='header'>End Time</div>
              <select
                id='endTime'
                value={data.EndTime}
                onChange={(e) =>
                  updateTimeWindow(data.Id, { EndTime: e.target.value })
                }
              >
                {endTimeSlots.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className='end'>
              <div
                className={
                  invalid?.includes(data.Id)
                    ? "percent-cnt-error"
                    : "percent-cnt"
                }
              >
                <input
                  className='percent-int'
                  value={data.Percentage}
                  onChange={handlePercentageChange}
                  max={100}
                />
                %
              </div>
            </div>
            <div className='delete-cont'>
              <img
                alt=''
                src={trash}
                className='delete'
                onClick={() => {
                  let updated = timeWindows.filter(
                    (timeWindow) => timeWindow.Id !== data.Id
                  );
                  setTimeWindows(updated);
                }}
              />
            </div>
          </div>
          <div className='window-bottom'>
            <div className='header'>Status:</div>
            <div className='radio-group'>
              <div className='radio-container'>
                {selectedStatus === "UNAVAILABLE" ? (
                  <div className='selected-radio-btn'>
                    <div className='selected-inner' />
                  </div>
                ) : (
                  <div
                    className='radio-btn'
                    onClick={() => handleStatusChange("UNAVAILABLE")}
                  />
                )}
                Unavailable
              </div>
              <div className='radio-container'>
                {selectedStatus === "BUSY" ? (
                  <div className='selected-radio-btn'>
                    <div className='selected-inner' />
                  </div>
                ) : (
                  <div
                    className='radio-btn'
                    onClick={() => handleStatusChange("BUSY")}
                  />
                )}
                Busy
              </div>
            </div>
          </div>
        </div>
      </div>
      {invalid?.includes(data.Id) ? (
        <div className='error'>
          A time window percentage must be between 1 and 100, and cannot be 0 or
          more than 100.
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ShiftTimeWindow;
