import React, { useState } from "react";
import { format } from "date-fns";
import "./InputDatePickerStyles.scss";
import calendar from "../../../assets/date.svg";
import DatePickerComponet from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface InputDatePickerProps {
  selectedDate: any;
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
}
const InputDatePicker: React.FC<InputDatePickerProps> = ({
  selectedDate,
  setSelectedDate,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className='InputDatePicker'
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <div className='date'>
        <div onClick={() => setIsOpen(!isOpen)} style={{ cursor: "pointer" }}>
          {format(selectedDate, "dd/MM/yyyy")}
        </div>
        <img alt='' src={calendar} className='calender' />

        {isOpen ? (
          <DatePickerComponet
            selected={selectedDate}
            onChange={(date) => {
              setSelectedDate(date);
              setIsOpen(false);
            }}
            dateFormat={"dd LLLL YYYY"}
            open={isOpen}
            onClickOutside={() => setIsOpen(false)}
            minDate={new Date()}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default InputDatePicker;
