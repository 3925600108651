import React, { useState } from "react";
import { format, isToday } from "date-fns";
import "./DatePicker2Styles.scss";
import DatePickerComponet from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RootState } from "../../../../application/store";
import { useSelector } from "react-redux";
import arrow from "../../../assets/down-white.png";
import { generateUniqueDates } from "../../../../application/utils";
interface DatePickerProps {
  selectedDate: any;
  setSelectedDate: React.Dispatch<React.SetStateAction<any>>;
  maxDays: number;
}
const DatePicker2: React.FC<DatePickerProps> = ({
  selectedDate,
  setSelectedDate,
  maxDays,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const getMaxDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + maxDays);
    return date;
  };
  const organizationDetails = useSelector(
    (state: RootState) => state.booking.organizationDetails
  );
  const shiftData = useSelector(
    (state: RootState) => state.booking.shiftDetails
  );
  const blockedDays = organizationDetails?.OpeningHours?.filter(
    (day: any) => day.Closed
  ).map((day: any) => day.Day);
  const dayToIndex: { [key: string]: number } = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };
  const isShiftAvailable = (day: Date): boolean => {
    const shiftsDays = shiftData
      .filter((shift: any) => shift.IsActive && shift.Type === "RECURRING")
      .flatMap((shift: any) => shift.ShiftActiveDay)
      .map((day: any) => day.Day.toLowerCase())
      .filter(
        (day: any, index: number, self: any) => index === self.indexOf(day)
      );

    const selectedDay = format(day, "eeee").toLowerCase();
    return shiftsDays.includes(selectedDay);
  };
  const blockedIndexes: number[] = blockedDays.map(
    (day: any) => dayToIndex[day]
  );

  const onetime = shiftData
    .filter((shift: any) => shift.IsActive && shift.Type === "ONETIME")
    .flatMap((shift: any) => shift.TimeSchedule);

  const isAllowedDate = (date: Date): boolean => {
    const isBlocked = blockedIndexes.includes(date.getDay());

    const isShiftAvailableValue = isShiftAvailable(date);

    const uniqueDates = generateUniqueDates(onetime);
    const isInRange = uniqueDates.includes(date.toLocaleDateString());

    return (!isBlocked && isShiftAvailableValue) || isInRange;
  };
  const formatDate = (date: Date): string => {
    return isToday(date) ? "Today" : format(date, "EEE, MMM d");
  };
  return (
    <div
      className='DatePicker-2'
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <div className='date'>
        {isOpen ? (
          <div>
            <DatePickerComponet
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                setIsOpen(false);
              }}
              dateFormat={"EEE, MMM d"}
              open={isOpen}
              onClickOutside={() => setIsOpen(false)}
              minDate={new Date()}
              maxDate={getMaxDate()}
              filterDate={isAllowedDate}
            />
          </div>
        ) : (
          <div />
        )}
        <div className='date-container' onClick={() => setIsOpen(!isOpen)}>
          {formatDate(selectedDate)}
        </div>
        <img alt='' src={arrow} className='arrow' />
      </div>
    </div>
  );
};

export default DatePicker2;
