import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../application/store";
import "./CustomBannerStyles.scss";
const CustomBanner: React.FC = () => {
  const BannerTemplate = useSelector(
    (state: RootState) => state.booking.organizationDetails.BannerTemplate
  );
  return (
    <div className='CustomBanner'>
      {BannerTemplate.texts.map((text: any) => {
        return (
          <div className={text.id}>
            {text.id === "mainText" ? <span>🎄</span> : ""}
            {text.content}
          </div>
        );
      })}
    </div>
  );
};
export default CustomBanner;
